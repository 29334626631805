.menu-button {
  height: 167px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 45px;
  border-radius: 10px;
  border: 1px solid #434343;
  color: #434343;

  /* text */


  margin-top: 10px;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}


.menu-button:active {
  transform: scale(1.05);
}



.menu-button:hover {

  color: white;
  border: 0px solid #434343;
  border-radius: 10px;
  background: linear-gradient(173deg, #FA8F2F -1.4%, #f02400 159.63%);
  transition: all 0.10s ease;
}


