
.form-tech-info {
  margin-top: 35px;
  max-width: 574px;
  color: #000;
  text-transform: lowercase;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: normal
}

.form-deadline {

  max-width: 574px;
  color: #000;
  text-transform: lowercase;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: normal

}
