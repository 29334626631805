.form-time-box {
  width: 275px;
  height: 61px;

}


.form-time-title {
  font-size: 16px;
  text-align: center;


}


.form-time-input {
  width: 100%;
  height: 61px;
  padding: 8px 24px;
  font-size: 20px;
  align-items: center;
  border-radius: 10px;
  border: 1px solid rgba(139, 139, 139, 0.49);
  background: #FFF;
  color: rgba(0, 0, 0, 0.49);


}