.form-date-box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3px;
}

.form-text {
  font-size: 12px;
  text-transform: uppercase;
  padding-left: 10px;
}

.form-date {

  height: 61px;
  padding-left: 10px;
  padding-right: 10px;

  flex-shrink: 0;

  border-radius: 10px;
  border: 1px solid rgba(139, 139, 139, 0.49);
  color:  #81817d;

}