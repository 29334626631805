

.logo-container {
  margin-top: 20px;
  width: 100%;
  height: 120px;
  overflow: hidden;

}


.logo {
  width: 400px;
  animation: logo-inner 1500ms ease forwards;

}

