
.form-area-input {
  height: 214px;
  width: 100%;

  padding: 8px 24px;
  border-radius: 10px;
  border: 1px solid rgba(139, 139, 139, 0.49);
  background: #FFF;

  outline-color: rgb(255, 149, 0);
}