.my-button {

  border-radius: 10px;
  width: 100%;
  height: 61px;

  /* color */

  background: linear-gradient(173deg, #FC9B32 -1.4%, #E81D0E 159.63%);
  color: #FFF;

  text-transform: lowercase;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;



}


.my-button:active {
  transform: scale(1.05);
  transition: all 0.05s ease;
}


