.footer-container {
  margin-top: 122px;
  height: 95px;
  flex-shrink: 0;
  background: #ffffff;

  display: flex;
  align-items: center;
  justify-content: center;

}


.footer-box {

}

.footer-release {

  color: #959595;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: lowercase;
  text-align: center;
  margin-bottom: 10px;

}


.footer-title {

  color: #959595;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: lowercase;
  text-align: center;
}


.footer-subtitle {
  margin-top: 10px;
  color: #959595;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: lowercase;
  text-align: center;

}


