/* schelude */


.schelude-container {
  margin-top: 50px;


}

.shelude-calendar {
  border-radius: 10px;

}


.schelude-info {
  width: 100%;
  height: 281px;
  border-radius: 10px;
  border: 1px solid #a0a096;

}

.shelude-title {
  margin-top: 50px;
  color: #434343;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;

  letter-spacing: 0.7px;

}

.shelude-subtittle {
  margin-top: 50px;
  color: #434343;
  text-align: center;
  font-size: 14px;
  font-style: normal;

  letter-spacing: 0.7px;

}



.card-list {
  padding-left: 0px;
}





/* create */




.filming-form-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}



/* create card */


.card-filming-container {
  margin-top: 50px;
  width: 100%;
  height: max-content;
  border: 1px solid rgba(139, 139, 139, 0.49);
  border-radius: 10px;

  list-style: none;

}


.card-filming-box {
  padding: 30px;
}

.card-filming-date {
  width: 100%;
  height: 50px;
  background: linear-gradient(173deg, #FC9B32 -1.4%, #E81D0E 159.63%);
  border-radius: 10px 10px 00px 0px;
  padding-left: 30px;
  padding-top: 15px;

  /*  */

  color: #ffffff;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.7px;

}


.card-filming-title {
  width: 100%;
  height: max-content;
  color: #434343;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.7px;
  margin-bottom: 20px;


}

.card-filming-author {
  width: 100%;
  height: max-content;
  color: #434343;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.7px;
  margin-bottom: 10px;
}

.card-filming-place {
  width: 100%;
  height: max-content;
}

.card-filming-conditions {
  margin-top: 20px;
  width: 100%;
  font-size: 14px;
  height: max-content;
}

.card-filming-time {
  margin-top: 20px;
}

.card-filming-user {
  margin-top: 20px;
  width: 100%;
  height: 30px;
  color: #434343;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.7px;
}

.card-filming-cloth {
  margin-top: 20px;
}




.card-filming-project {
  margin-top: 20px;
}







/* list-filming */



.list-filming-container {

}

.list-filming-top {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 20px;

  /*  */

  border-radius: 10px;
  border: 1px solid #434343;


}

.list-filming-title {

  color: white;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #434343;


}

.list-filming-btn {
}







.filming-date {
  font-size: 11px;
  color: #434343;
}

.filming-color {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

}

.filming-time {
  font-size: 12px;
  text-transform: lowercase;
  color: #434343;
  margin-left: 10px;

}

.filming-title {
  font-size: 12px;
  text-align: center;
  color: #434343;
  margin-left: 20px;

}

.filming-name {
  font-size: 12px;
  text-transform: lowercase;
  color: #434343;
  margin-left: 50px;

}

.filming-line {

}



/*  */



.card-filming-loading {
  margin-top: 50px;
  color: #434343;
  text-align: center;
  font-family: Montserrat;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.7px;
}






/*  */

.empty-card-list {
  margin-top: 50px;
  color: #434343;
  text-align: center;
  font-family: Montserrat;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.7px;
}


.filming-deadline {

  margin-top: 15px;
  max-width: 100%;
  color: #000;
  text-transform: lowercase;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: normal

}

/* plan */

.plan-card-title {
  margin-top: 15px;
  margin-bottom: 15px;
  max-width: 100%;
  color: #434343;
  text-transform: lowercase;
  text-align: center;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal
}

.plan-card-subtitle {
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 20px;
  max-width: 100%;
  color: #434343;
  text-transform: lowercase;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: normal
}


/* EDIT FILMING */

.filming-edit-title {
  margin-top: 15px;
  text-align:  center;
}