.form-question-box {

}


.form-input {
  height: 61px;
  padding: 8px 24px;
  border-radius: 10px;
  border: 1px solid rgba(139, 139, 139, 0.49);
  background: #FFF;



}



.form-input:focus {
  outline: solid 2px;
  outline-color: rgb(255, 149, 0)
}






